import React from 'react';
import RouterPage from './routes/RouterPage';

function App() {
  return (
    <RouterPage />
  );
}

export default App;
