import axios from "axios";
import { BASE_API_URL } from "./Razorpay";

// Fetch order data by ID
export const fetchInvoiceDetails = (id) => {
  return axios.post(`${BASE_API_URL}/Order/GetOrderData_ByOrderID`, {
    orderID: id,
  });
};

// Fetch PDF data
export const fetchPdfData = (id) => {
  return axios.post(`${BASE_API_URL}/Order/DownloadCustomerGSTInvoice`, {
    orderID: id,
  });
};
