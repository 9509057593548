import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PdfViewerPage from "../pages/PdfViewerPage";
import InvoicePage from "../pages/InvoicePage";
import ErrorPage from "../components/ErrorPage";
import MainLayout from "../layouts/MainLayout";

function RouterPage() {
  return (
    <Router>
      {/* <MainLayout> */}
      <Routes>
        {/* <Route path="/pdf/:id" element={<InvoicePage />} /> */}
        <Route path="/pdf" element={<InvoicePage />} />
        <Route path="/invoice" element={<PdfViewerPage />} />
        {/* <Route path="/invoice/:id" element={<PdfViewerPage />} /> */}
        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {/* </MainLayout> */}
    </Router>
  );
}

export default RouterPage;
