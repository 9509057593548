import React from 'react';
import '../styles/ErrorPage.css'; // Import custom styles

function ErrorPage() {
  return (
    <div className="error-page-container">
      <h1 className="error-title">Oops!</h1>
      <p className="error-message">Something went wrong. Please try again later.</p>
      <a className="error-link" href="/">Go back to Home</a>
    </div>
  );
}

export default ErrorPage;
