import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { fetchInvoiceDetails } from "../services/api"; // Import updated service function
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import "../styles/InvoicePage.css"; // Adjusted import path
import axios from "axios";
import { BASE_API_URL, Rezorpay_Key } from "../services/Razorpay";

const InvoicePage = () => {
  // const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Get the value of a specific query parameter
  const myQueryParam = queryParams.get("oid"); // e.g., ?myParam=value

  const [orderData, setOrderData] = useState({
    invoiceID: 0,
    orderID: 0,
    subTotal: 0,
    taxAmount: 0,
    totalAmount: 0,
    paidAmount: 0,
    remainingAmount: 0,
    approxTotalWeight: 0,
    disPer: 0,
    discountAmt: 0,
    beforDisAmt: 0,
    noOfItems: 0,
    speedTypeID: 0,
    laundryTypeID: 0,
    serviceID: 0,
    isDiscount: false,
    speedTypeName: "",
    laundryTypeName: "",
    orderDate: "",
    orderNo: "",
    serviceName: "",
    perKGAmount: 0,
    responseObject: [],
  });

  useEffect(() => {
    fetchItemData();
  }, [navigate]);

  const fetchItemData = async () => {
    try {
      const response = await fetchInvoiceDetails(myQueryParam);
      if (response.data.isSuccess) {
        setOrderData(response.data);
      } else {
        setOrderData([]);
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
      navigate("/error");
    }
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    setIsLoading(true);

    // Load Razorpay SDK script
    const res = await loadRazorpayScript();
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      setIsLoading(false);
      return;
    }

    try {
      // Step 1: Request order creation from your backend
      const orderResponse = await axios.post(
        `${BASE_API_URL}/gudpay/SetPaymentByLinkForOrder`,
        {
          orderID: myQueryParam,
          invoiceID: orderData.invoiceID,
          amt: orderData.remainingAmount, // The amount
          currency: "INR",
        }
      );
      // console.log(orderResponse);
      // console.log(orderResponse.data.isSuccess);
      // console.log(orderResponse.data.razorOrderNo);
      if (
        !orderResponse.data.isSuccess ||
        !orderResponse.data ||
        !orderResponse.data.razorOrderNo
      ) {
        alert("Failed to create order. Please try again.");
        setIsLoading(false);
        return;
      }
      // console.log("first");
      const tmpRazorOrderNo = orderResponse.data; // Extract order ID from backend response
      // console.log(tmpRazorOrderNo.razorOrderNo);
      // Step 2: Set up the Razorpay options
      const options = {
        key: Rezorpay_Key,
        amount: `${orderData.remainingAmount * 100}`,
        currency: "INR",
        name: "GUDFYND Service",
        description: "Payment for Order",
        image: `${BASE_API_URL}/media/logos/Logo.png`,
        order_id: `${tmpRazorOrderNo.razorOrderNo}`,
        handler: async function (response) {
          try {
            // console.log(response);
            alert("Payment successful!");
            // alert("Payment ID: " + response.razorpay_payment_id);
            // alert("Order ID: " + response.razorpay_order_id);
            // alert("Signature: " + response.razorpay_signature);

            // Step 3: Send the payment details to your backend to verify the payment
            const verificationResponse = await axios.post(
              `${BASE_API_URL}/gudpay/UpdateOrderRazBalance`,
              {
                orderID: myQueryParam,
                invoiceID: orderData.invoiceID,
                razorTransactionID: response.razorpay_payment_id,
                razorOrderNo: tmpRazorOrderNo.razorOrderNo,
                receiptNo: tmpRazorOrderNo.receiptNo,
              }
            );
            // console.log(verificationResponse);

            if (verificationResponse.data.isSuccess) {
              fetchItemData();
              alert("Payment verified successfully!");
            } else {
              alert("Payment verification failed. Please contact support.");
            }
          } catch (error) {
            alert("Payment verification error: " + error.message);
          }
          fetchItemData();
          setIsLoading(false);
        },
        prefill: {
          name: `${orderData.customerName}`,
          email: "customer@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: function () {
            setIsLoading(false);
            alert("Payment process was canceled. No transaction was made.");
          },
        },
      };

      const paymentObject = new window.Razorpay(options);

      // Track if payment modal is closed
      let paymentModalClosed = false;

      paymentObject.on("close", () => {
        paymentModalClosed = true;
      });

      // Open the Razorpay payment modal
      paymentObject.open();

      // Check if payment modal was closed without completing payment
      const checkPaymentStatus = setInterval(() => {
        if (paymentModalClosed) {
          clearInterval(checkPaymentStatus);
          fetchItemData();
          setIsLoading(false);
          alert("Payment process was canceled.");
        }
      }, 1000); // Check every second
    } catch (error) {
      console.error("Error in payment processing:");
      console.error(error);
      // console.error("Error in payment processing:", error);
      alert("An error occurred while processing your payment.");
      fetchItemData();
      setIsLoading(false);
    }
  };

  return (
    <div className="invoice-container">
      <div className="invoice-header">
        <button
          className="back-btn"
          onClick={() => navigate(`/invoice?oid=${myQueryParam}`)}
        >
          {/* <FontAwesomeIcon icon={faArrowLeft} /> &nbsp;  */}
          Go to PDF
        </button>
        {orderData.remainingAmount > 0 && (
          <button
            className="pay-now-btn"
            onClick={handlePayment}
            disabled={isLoading}
          >
            {isLoading ? (
              "Processing..."
            ) : (
              <>
                <FontAwesomeIcon icon={faMoneyCheck} /> Pay Now (INR{" "}
                {orderData.remainingAmount})
              </>
            )}
          </button>
        )}
      </div>
      <div className="table-responsive">
        <table className="invoice-table">
          <thead>
            <tr>
              <th style={{ width: "10%" }}>Sr.</th>
              <th style={{ width: "15%" }}>Category</th>
              <th style={{ width: "40%" }}>Item</th>
              {orderData.serviceID !== 5 && (
                <th style={{ width: "10%" }}>Rate</th>
              )}
              <th style={{ width: "10%" }}>Qty</th>
              {/* {orderData.serviceID === 5 && (
                <th style={{ width: "10%" }}>Approx Weight</th>
              )} */}
              {orderData.serviceID !== 5 && (
                <th style={{ width: "15%" }}>Total</th>
              )}
            </tr>
          </thead>
          <tbody>
            {orderData.responseObject.length > 0 ? (
              orderData.responseObject.map((item, index) => (
                <tr key={item.orderDetailID}>
                  <td>{index + 1}</td>
                  <td>{item.itemCategoryName}</td>
                  <td>{item.itemName}</td>
                  {orderData.serviceID !== 5 && <td>₹ {item.rateOnOrder}</td>}
                  <td>{item.qty}</td>
                  {/* {orderData.serviceID === 5 && <td>{item.approxWeight} kg</td>} */}
                  {orderData.serviceID !== 5 && <td>₹ {item.lineTotal}</td>}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No data available</td>
              </tr>
            )}
            <tr>
              <td
                style={{ textAlign: "right", paddingRight: "15px" }}
                colSpan={orderData.serviceID === 5 ? "3" : "5"}
              >
                Before Discount
              </td>
              <td>₹ {orderData.beforDisAmt}</td>
            </tr>
            {orderData.serviceID === 5 && (
              <tr>
                <td
                  style={{ textAlign: "right", paddingRight: "15px" }}
                  colSpan="3"
                >
                  Laundry Type ( Per KG Amount )
                </td>
                <td>
                  {orderData.laundryTypeName} ( ₹ {orderData.perKGAmount} )
                </td>
              </tr>
            )}
            {orderData.serviceID === 5 && (
              <tr>
                <td
                  style={{ textAlign: "right", paddingRight: "15px" }}
                  colSpan="3"
                >
                  Approx Total Weight
                </td>
                <td>{orderData.approxTotalWeight} kg</td>
              </tr>
            )}
            <tr>
              <td
                style={{ textAlign: "right", paddingRight: "15px" }}
                colSpan={orderData.serviceID === 5 ? "3" : "5"}
              >
                Sub Total
              </td>
              <td>₹ {orderData.subTotal}</td>
            </tr>
            {orderData.isDiscount && (
              <tr>
                <td
                  style={{ textAlign: "right", paddingRight: "15px" }}
                  colSpan={orderData.serviceID === 5 ? "3" : "5"}
                >
                  Discount ( {orderData.disPer}% )
                </td>
                <td>₹ {orderData.discountAmt}</td>
              </tr>
            )}
            <tr>
              <td
                style={{ textAlign: "right", paddingRight: "15px" }}
                colSpan={orderData.serviceID === 5 ? "3" : "5"}
              >
                Tax Amount
              </td>
              <td>₹ {orderData.taxAmount}</td>
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td
                style={{ textAlign: "right", paddingRight: "15px" }}
                colSpan={orderData.serviceID === 5 ? "3" : "5"}
              >
                Total Amount (INR)
              </td>
              <td>₹ {orderData.totalAmount}</td>
            </tr>
            <tr style={{ color: "green", fontWeight: "bold" }}>
              <td
                style={{ textAlign: "right", paddingRight: "15px" }}
                colSpan={orderData.serviceID === 5 ? "3" : "5"}
              >
                Paid Amount
              </td>
              <td>₹ {orderData.paidAmount}</td>
            </tr>
            <tr style={{ color: "red", fontWeight: "bold" }}>
              <td
                style={{ textAlign: "right", paddingRight: "15px" }}
                colSpan={orderData.serviceID === 5 ? "3" : "5"}
              >
                Remaining Amount
              </td>
              <td>₹ {orderData.remainingAmount}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvoicePage;
